<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Cari Yönetimi | Yeni Ekle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Cari Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Cari Listesi",
          href: "/current-account",
        },
        {
          text: "Cari Ekle",
          active: true,
        },
      ],
      values: [],
      type: "",
      account_holder: "",
      value_items: [],
      types: [
        { value: 1, name: "Personel" },
        { value: 2, name: "Acenta" },
        { value: 3, name: "Otel" },
        { value: 4, name: "Müşteri" },
      ],
    };
  },
  watch: {
    type(value) {
      console.log(value);
      console.log(value.name);
      if (value.name == "Personel") {
        this.getData("/employee/getAll", "value_items");
      }
      if (value.name == "Acenta") {
        this.getData("/agency/getAll", "value_items");
      }
      if (value.name == "Otel") {
        this.getData("/hotel/getAll", "value_items");
      }
      if (value.name == "Müşteri") {
        this.getData("/customer/getAll", "value_items");
      }
    }
  },
  methods: {
    submit() {
      const api_url = process.env.VUE_APP_BASEURL + "/current-account";
      const data = {
        values: JSON.stringify(this.values),
        type: this.type.value,
        account_holder: this.account_holder,
      } 
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/current-account");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData(endpoint, dataKey, callback = null) {
      axios
        .get(process.env.VUE_APP_BASEURL + endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this[dataKey] = response.data?.data;
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-6 col-md-6">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="form-group mb-3">
                    <label for="bank">Hesap Adı</label>
                    <input v-model="account_holder" type="text" class="form-control" id="bank" />
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="form-group mb-3">
                    <label for="acccount_holder">Hesap Türü</label>
                    <multiselect v-model="type" :options="types" label="name" :hover="false">
                    </multiselect>                    
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="form-group mb-3">
                    <label for="type">Cari</label>
                    <multiselect v-model="values" :options="value_items" label="name" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Kaydet</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
